<template>
  <ContentWrapper :fullHeight="true">
    <template v-slot:title>Profile</template>
    <Container>
      <div class="first-wrapper">
        <h3>Successful Referrals</h3>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th class="row-1">Display Name </th>
              <th class="row-1">Date Joined</th>
              <th class="row-1">Verified</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in referrals" :key="index">
              <td>{{item.name}}</td>
              <td>{{ item.created_at | moment }}</td>
              <td><font-awesome-icon :class="{verified: item.email_verify_flag}" icon="check-circle" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      
    </Container>
  </ContentWrapper>
</template>

<script>
import GameRoomList from '@/modules/Home/Dashboard/components/GameRoomList';
import SubmitButton from '@/components/SubmitButton';
import { referralsIndex } from "@/services/api/users.js";
import moment from "moment";

export default {
  components:{
    GameRoomList,
    SubmitButton
  },
  filters: {
    moment(date){
      return moment(date).format('DD MMM YYYY')
    }
  },
  data(){
    return{
      referrals: [],
    }
  },
  async mounted(){
    let req = await referralsIndex()
    this.referrals = req.data.response.referrals.data
  }
}
</script>

<style lang="scss" scoped>
.first-wrapper{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
table{
  border-collapse: collapse;
  background-color: grey;
  border: 1px solid #EEEEEE;
  border-radius: 20px;
  text-align: center;
  table-layout: fixed;
  white-space: nowrap;
  width: 100%;
  @include respond(tab-port){
    font-size: $twelve;
  }
  @include respond(phone){
    font-size: $ten;
  }
  th{
    background-color: #414241;
    // text-transform: uppercase;
    letter-spacing: 0.1rem;
    padding: .75rem 2rem;
    color: white;

    span{
      float: right;
    }
  }
  td{
    color: black;
    background-color: white;
    padding: 1rem 2rem;

    .verified{
      color: green;
    }
  }
  .row-1{
  width: 33%;
  }
}
</style>